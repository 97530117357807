import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import bodybuilder from 'bodybuilder'
import { DropdownComponent, DropdownOption } from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { Topics } from 'components/icons/topics'
import {
  AggregationType,
  DateRangesAsDropdownOptions,
  FilterType,
  getMessageSendDateFilterField,
  IndexName,
  SuspectFilterDropdownOptions,
  toFilter
} from 'elasticsearch/constants'
import { ResponseAggregate, ResponsePayload } from 'elasticsearch/types'
import { ElasticsearchComponent, ElasticsearchProps } from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import { startCase } from 'lodash'
import React, { ComponentType } from 'react'
import { Button } from 'reactstrap'
import { AjaxWrapper, HttpMethod } from '../../generic/ajaxWrapper'
import { EditableDropdownCell, EditCellProps } from '../../generic/editCell'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import * as GenericUtils from '../../generic/utility'
import { ConnectedComponentClass } from 'react-redux'
import { Fields } from '../../shared/modals'
import * as Flash from '../flash'
import * as Sentry from '@sentry/browser'
import * as Modals from '../../shared/modals'
import * as Utils from "./utils";

export class TopicsComponentClass extends RasaAnalyticsComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      selectedDateRange: this.findDropdownOptionByValue(DateRangesAsDropdownOptions, Constants.AnalyticsFilterKeys.SELECTED_DATE_RANGE, DateRangesAsDropdownOptions[2].value),
      selectedSource: this.findDropdownOptionByValue(Constants.SourceFilters,Constants.AnalyticsFilterKeys.SELECTED_SOURCE),
      selectedSuspectClick:  this.findDropdownOptionByValue(SuspectFilterDropdownOptions, Constants.AnalyticsFilterKeys.SELECTED_SUSPECT_CLICK),
    }
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
  }

  public render() {
    return (
      <div className="analytics-component">
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Topics'}
          description={[
            'The content topics your contacts are clicking on the most.',
          ]}
        />
        <div className="dropdown-header">
          <div className="dropdown-inline">
            <DropdownComponent
              data={DateRangesAsDropdownOptions}
              selected={this.state.selectedDateRange.key}
              onChange={this.dateChanged}
            />
          </div>
          <div className="dropdown-inline">
            <DropdownComponent
              data={Constants.SourceFilters}
              selected={this.state.selectedSource.key}
              onChange={this.sourceChanged}
            />
          </div>
          <div className="dropdown-inline">
            <DropdownComponent
              data={SuspectFilterDropdownOptions}
              selected={this.state.selectedSuspectClick.key}
              onChange={this.suspectedClickChanged}
            />
          </div>
        </div>
        <div className="date-range-picker">
        {this.state.selectedDateRange.key === '7' ?
        <DateRangePicker onChange={this.createCustomDate}
          min={this.state.minCustomDateRange} max ={new Date()} /> : null}
        </div>
        <AnalyticsTopicsTable
          openModal={() => this.props.openModal(ExportTopicReportStartedModal.key, {})}
          dateRange={this.state.selectedDateRange.value}
          source={this.state.selectedSource.value}
          suspectClick={this.state.selectedSuspectClick.value}
          timezone={this.state.timezone}
          useTimezoneForAnalytics={this.state.useTimezoneForAnalytics}
        />
        <div className="modal-wrapper">
          <ExportTopicReportStartedModal data={this.props.modals} closeModal={this.props.closeModal} title="Export Started" />
        </div>
      </div>
    )
  }

  private findDropdownOptionByValue = (filters: DropdownOption[], queryKey: string, defaultValue?: string) => {
    const valueToFind = this.getQuery(queryKey, defaultValue)
    const found = filters.find((x) => x.value === valueToFind)
    return found || filters[0]
  }
}

interface Topic {
  name: string,
  key: string,
  totalClicks?: number,
  uniqueClicks?: number
}

type Topics = Topic[]

interface TopicsProps extends ElasticsearchProps<Topics> {
  dateRange: any,
  source: any,
  openModal: any,
  suspectClick: string,
  timezone?: string,
  useTimezoneForAnalytics?: any
}

interface TopicsState {
  loaded: boolean,
}

const TAG_NAME_AGG: string = 'tag'
const SOURCE_CUSTOM: string = 'Custom'

class TopicsTableComponent extends ElasticsearchComponent<Topics, TopicsProps, TopicsState> {
  private xlsxExport: any = null
  constructor(p: TopicsProps) {
    super(p, IndexName.TAGS)
    this.state = {
      loaded: false,
    }
    this.reportName = Constants.REPORT_NAMES.TOPICS
  }

  public parseResponse(payload: ResponsePayload): Promise<Topics> {
    this.setState({
      loaded: true,
    })
    const aggregations = payload.aggregations[TAG_NAME_AGG]
    return Promise.resolve(aggregations.buckets.map((aggregation: ResponseAggregate) => ({
      name: startCase(aggregation.key.toLowerCase()),
      key: aggregation.key, // key is the original tag name. Needed in export
      totalClicks: aggregation.doc_count,
      uniqueClicks: aggregation.unique.value,
    })))
  }

  public componentDidUpdate(oldProps: TopicsProps) {
    if (
      this.props.dateRange !== oldProps.dateRange ||
      this.props.source !== oldProps.source ||
      this.props.suspectClick !== oldProps.suspectClick ) {
      this.search()
    }
  }

  public searchPayload(): any {
    const search = this.generateSearchQuery()
    return this.addAggregation(search, {
      type: AggregationType.terms,
      field: 'text.keyword',
      name: TAG_NAME_AGG,
      unique_on: 'community_person_id',
      extra: { size: Constants.ANALYTICS_TOPICS_LIMIT },
    }).build()
  }

  public render = () => <div>
    <div className="topics-chart">
      {this.props.results && this.props.results.length > 0 ?
      <div>
        <Button
          disabled={this.props.results.length < 1}
          onClick={() => this.xlsxExport.save()}>
          Export xlsx
        </Button>
        <ExcelExport data={this.props.results}
          fileName="RasaAdminReports.xlsx"
          ref={(exporter) => {this.xlsxExport = exporter}}>
          <Grid data={this.props.results} className="analytics-counts-grid" scrollable="none">
            <GridColumn field="name" title="Topic" />
            <GridColumn field="totalClicks" title="Total Clicks"/>
            <GridColumn field="uniqueClicks" title="Unique Clicks"/>
            <GridColumn cell={this.SourceEditCell} width="50%" />
          </Grid>
        </ExcelExport>
      </div> :
      <div>
        <p className="no-data-tag">
          {Constants.NO_DATA_COPY}
        </p>
    </div>
      }
    </div>
  </div>

  protected SourceEditCell = (props: EditCellProps) => {
    return <EditableDropdownCell
        {...props}
        showExport={true}
        onClickExport={(result) => this.getEmailsByTopic(result)}
      />
  }

  private generateSearchQuery = (): bodybuilder.Bodybuilder => {
    let search = bodybuilder().size(0).filter(
      FilterType.range, getMessageSendDateFilterField(this.props.dateRange, this.props.useTimezoneForAnalytics), toFilter(this.props.dateRange, this.props.timezone, this.props.useTimezoneForAnalytics))
    if (this.props.source === Constants.SourceFilterOption.RASA_TAGS) {
      search = search.notFilter(FilterType.term, 'source.keyword', SOURCE_CUSTOM)
    } else if (this.props.source === Constants.SourceFilterOption.CUSTOM_TAGS) {
      search = search.filter(FilterType.term, 'source.keyword', SOURCE_CUSTOM)
    }
    if (this.props.suspectClick && Utils.isRealClickSelected(this.props.suspectClick)) {
      search.addFilter(FilterType.term, 'suspect_click', 0)
    }

    return search
  }
  private getEmailsByTopic = (topic: Topic) => {
    const url: URL = new URL(`/${this.communityId}/topics-unique-emails/export`, AjaxWrapper.getServerUrl())
    url.search = new URLSearchParams([['topic', topic.key]]).toString()
    const search = this.generateSearchQuery().addFilter(FilterType.term, 'text.keyword', topic.key)

    const payload = {
      topicName: topic.key,
      search: search
        .build()
    }
    AjaxWrapper.ajax(url.href, HttpMethod.POST, payload)
      .then((response) => {
        const { status, data } = response

        if (status === 'done' && data) {
          return GenericUtils.downloadTextFile(data, 'csv', topic.name)
        } else if (status === 'pending') {
          return this.props.openModal()
        }

        throw new Error(`Unknown response from ${url.href}`)
      })
      .catch((err) => {
        this.context.store
          .dispatch(Flash.showFlashError('\'Oops! There was a problem exporting your topic report. Please try again.'))
        Sentry.captureException(err)
      })
  }
}

class ExportTopicReportStartedModal extends Modals.ModalComponent {
  public static key: string = 'topicReportExportStart'
  constructor(props: Modals.ModalComponentProps) {
    super(props, ExportTopicReportStartedModal.key)
  }
  protected renderChildren(data: any) {
    return 'We are exporting your topic report now. Check your inbox for a link to download the list.'
  }
}

export const AnalyticsTopicsTable: ConnectedComponentClass<ComponentType<TopicsTableComponent>, Fields> = GenericRedux.registerNewComponent(
  TopicsTableComponent, 'home_topics', {})

export const TopicsComponent = GenericRedux.registerNewComponentWithModals(
  TopicsComponentClass,
  'analytics_topic_modal',
  [ExportTopicReportStartedModal.key],
  {}
)
