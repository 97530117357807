import React, { Component, ComponentType } from 'react'
import * as GenericRedux from '../../generic/genericRedux'
import { ConnectedComponentClass } from 'react-redux'
import { Fields } from '../../shared/modals'
import bodybuilder from 'bodybuilder'
import { ElasticsearchComponent, ElasticsearchProps } from '../../generic/elasticSearchComponent'
import { AggregationType, FilterType, IndexName, ResponsePayload } from '../../elasticsearch'
import * as Constants from './constants'
import { Button } from 'reactstrap'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import pipe from 'lodash/fp/pipe'
import * as Elasticsearch from '../../elasticsearch'
import { Loading } from 'components/loading'
import over from 'lodash/fp/over'
import map from 'lodash/fp/map'
import unnest from 'lodash/fp/unnest'
import zip from 'lodash/fp/zip'
import zipAll from 'lodash/fp/zipAll'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { HeaderComponent } from '../header/component'
import { Dataset } from '../../generic/dataset'
import { RasaContext } from '../../context'
import * as Utils from './utils'
import {AI_KNOWLEDGE_TIER_ANALYTICS_URL, KNOWLEDGE_TIER_RANGES} from "./constants";
import { getOptionValue } from '../../constants'

export const DEFAULT_COMMUNITY_PERSON_ID_LIMIT = 500

interface ProgressThreshold {
  level_one: number,
  level_two: number,
}

interface DefaultThreshold {
  ctr: ProgressThreshold,
  unique_click: ProgressThreshold,
  unique_open: ProgressThreshold,
}

interface Point {
  deliveryCount: number,
  memberCount: number,
  popularTopics?: string,
  tier: string,
  totalClickCount: number,
  totalClickRate: string,
  totalDeliveryCount: number,
  totalOpenCount: number,
  totalOpenRate: string,
  uniqueClickCount: number,
  uniqueClickRate: string,
  uniqueOpenCount: number,
  uniqueOpenRate: string,
}

type Points = Point[]

interface AiKnowledgeTierProps extends ElasticsearchProps<Points> {
  hideGrid: boolean,
}

interface AiKnowledgeTierState {
  isLoading: boolean,
  loaded: boolean,
  knowledge_tier_report_threshold: DefaultThreshold
}

const EMPTY_TIER = {
  memberCount: 0,
  totalClickCount: 0,
  totalDeliveryCount: 0,
  totalOpenCount: 0,
  uniqueClickCount: 0,
  uniqueOpenCount: 0,
}

const EMPTY_TIERS = [
  {tier: 'A', ...EMPTY_TIER},
  {tier: 'B', ...EMPTY_TIER},
  {tier: 'C', ...EMPTY_TIER},
  {tier: 'D', ...EMPTY_TIER},
  {tier: 'E', ...EMPTY_TIER},
  {tier: 'F', ...EMPTY_TIER},
  {tier: 'G', ...EMPTY_TIER},
  {tier: 'H', ...EMPTY_TIER},
  {tier: 'I', ...EMPTY_TIER},
]

const DEFAULT_THRESHOLD: DefaultThreshold = {
  ctr: {level_one: 30, level_two: 50},
  unique_click: {level_one: 40, level_two: 60},
  unique_open: {level_one: 35, level_two: 65}
}

const collectCounts = pipe(
  over([
    pipe(
      Elasticsearch.createAggSelector(Utils.AggFieldNames.UniqueTagCount),
      map(
        over([
          Elasticsearch.selectValueAgg(Utils.AggFieldNames.DeliveryCount),
          Elasticsearch.selectValueAgg(Utils.AggFieldNames.UniqueClickCount),
          Elasticsearch.selectValueAgg(Utils.AggFieldNames.UniqueOpenCount),
          Elasticsearch.selectValueAgg(Utils.AggFieldNames.UniqueCount),
          Elasticsearch.selectValueAgg(Utils.AggFieldNames.TotalClickCount),
          Elasticsearch.selectValueAgg(Utils.AggFieldNames.TotalOpenCount),
          pipe(
            Elasticsearch.selectAggBuckets(Utils.AggFieldNames.TopCommunityPersonIds),
            map('key'),
          )
        ]),
      ),
    ),
  ]),
  zipAll,
  map(unnest),
  map(([
         deliveryCount,
         uniqueClickCount,
         uniqueOpenCount,
         memberCount,
         totalClickCount,
         totalOpenCount,
         communityPersonIds,
       ]) => ({
    deliveryCount,
    memberCount,
    totalClickCount,
    totalOpenCount,
    uniqueClickCount,
    uniqueOpenCount,
    communityPersonIds,
  })),
  zip(EMPTY_TIERS),
  map(([emptyTier, responseTier]) => ({...emptyTier, ...responseTier})),
)

const selectEngagement: any = pipe(
  collectCounts,
  map(({
         tier,
         totalClickCount,
         totalOpenCount,
         uniqueClickCount,
         deliveryCount,
         uniqueOpenCount,
       }: any) => ({
    tier,
    totalClickRate: Utils.asPercentage((totalClickCount / deliveryCount) || 0),
    totalOpenRate: Utils.asPercentage((totalOpenCount / deliveryCount) || 0),
    uniqueClickRate: Utils.asPercentage((uniqueClickCount / deliveryCount) || 0),
    uniqueOpenRate: Utils.asPercentage((uniqueOpenCount / deliveryCount) || 0),
  })),
)

const selectPopularTopics = pipe(
  Elasticsearch.createAggSelector('popular_topics'),
  map('key'),
)

export class AiKnowledgeComponent extends Component<any, any> {
  constructor(props) {
    super(props)
  }

  public render() {
    return(
      <div className="ai-knowledge">
        <HeaderComponent
          title={'REPORTS'}
          subTitle={'AI Knowledge Tiers'}
          description={[this.description()]}
        />
        <AiKnowledgeTierComponent />
      </div>
    )
  }

  private description = () => {
    const ai_knowledge_tier_analytics_url = getOptionValue('AI_KNOWLEDGE_TIER_ANALYTICS_URL', AI_KNOWLEDGE_TIER_ANALYTICS_URL);

    return `<a href="${ai_knowledge_tier_analytics_url}">Learn more about AI Knowledge Tiers here.</a>`
  }
}

class AiKnowledgeTierTableComponent extends ElasticsearchComponent<Points, AiKnowledgeTierProps, AiKnowledgeTierState> {
  public static contextType = RasaContext
  private xlsxExport: any = null

  constructor(props: any) {
    super(props, IndexName.COMMUNITY_PERSON_ACTION_ROLLUP)
    this.state = {
      isLoading: false,
      loaded: false,
      knowledge_tier_report_threshold: DEFAULT_THRESHOLD
    }

    this.reportName = Constants.REPORT_NAMES.AI_KNOWLEDGE_TIER
  }

  componentDidMount() {
    super.componentDidMount()
    this.context.user.init().then(({activeCommunity}) => {
      const params = [
        {param: 'key', value: 'knowledge_tier_report_threshold'}
      ]
      new Dataset().loadCommunityDataset('communityConfiguration', activeCommunity.communityId, params)
        .then((configuration) => {
          if (configuration.length > 0 && configuration[0].length > 0) {
            this.setState({
              knowledge_tier_report_threshold: {...JSON.parse(configuration[0][0].value)},
            })
          }
        })
    })
  }

  public parseResponse(payload: ResponsePayload): Promise<Points> {
    const result = payload.aggregations.unique_tags.buckets

    if (result.length === 0) {
      this.setState({
        isLoading: false,
        loaded: true,
      })
      return Promise.resolve([])
    }

    const mappedData: Point[] = zipAll([selectEngagement(payload), collectCounts(payload), Constants.TIER_LABELS])
      .map(([e, c, t]: any) => ({...e, ...c, ...{tier: t}}))

    return Promise.all(mappedData.map((md: any) => {
      if (md.communityPersonIds.length) {
        return this.getPopularTopics(md.communityPersonIds)
      }

      return ''
    })).then((topics) => {

      const data = mappedData.map((d, i: number) => {
        return {
          ...d,
          popularTopics: topics[i],
        }
      })

      this.setState({
        isLoading: false,
        loaded: true,
      })
      return Promise.resolve(data.reverse())
    })
  }

  public searchPayload(): any {
    this.setState({
      isLoading: true
    })
    return bodybuilder()
      .size(0)
      .aggregation(
        AggregationType.range,
        'unique_tags',
        {
          ranges: KNOWLEDGE_TIER_RANGES,
        },
        'unique_tags',
          aggs => aggs
            .aggregation('sum', 'total_opens', 'total_open_count')
            .aggregation('sum', 'total_clicks', 'total_click_count')
            .aggregation('sum', 'unique_opens', 'unique_open_count')
            .aggregation('sum', 'unique_clicks', 'unique_click_count')
            .aggregation('sum', 'deliveries', 'delivery_count')
            .aggregation('cardinality', 'community_person_id', 'unique_count')
            .aggregation(
              AggregationType.terms,
              'community_person_id',
              {
                field: 'community_person_id',
                size: DEFAULT_COMMUNITY_PERSON_ID_LIMIT,
              },
              'top_community_person_ids'
            )
      )
      .build()
  }

  public render = () => <div className="analytics">
    <div className="engagement-chart">
      {this.props.results && this.props.results.length > 0 ?
        <div>
          {!this.props.hideGrid &&
            <div className="analytics-component">
              <Button
                className="mb-4"
                disabled={this.props.results.length < 1}
                onClick={() => this.xlsxExport.save()}>
                Export xlsx
              </Button>
              <ExcelExport data={this.props.results}
                           fileName="RasaAdminReports.xlsx"
                           ref={(exporter) => {this.xlsxExport = exporter}}>
                <Grid data={this.props.results}>
                  <GridColumn field="tier" title="Tier" cell={this.customTierCell}/>
                  <GridColumn field="memberCount" title="Subscribers" />
                  <GridColumn field="popularTopics" title="Popular Topic"/>
                  <GridColumn
                    field="totalClickRate"
                    title="CTR (%)"
                    cell={(props) =>
                      <ProgressAnalyzer
                        progressValue={props.dataItem.totalClickRate}
                        progressThreshold={this.state.knowledge_tier_report_threshold.ctr} />
                    } />
                  <GridColumn
                    field="uniqueOpenRate"
                    title="Unique Opens (%)"
                    cell={(props) =>
                      <ProgressAnalyzer
                        progressValue={props.dataItem.uniqueOpenRate}
                        progressThreshold={this.state.knowledge_tier_report_threshold.unique_open} />
                    } />
                  <GridColumn
                    field="uniqueClickRate"
                    title="Unique Click (%)"
                    cell={(props) =>
                      <ProgressAnalyzer
                        progressValue={props.dataItem.uniqueClickRate}
                        progressThreshold={this.state.knowledge_tier_report_threshold.unique_click} />
                    } />
                </Grid>
              </ExcelExport>
            </div>}
        </div> :
        <div>
          { this.state.isLoading ?
          <Loading size="64"/> :
          <p className="no-data-tag">
            {Constants.NO_DATA_COPY}
          </p>}
        </div>}
    </div>
  </div>

  protected searchError() {
    this.setState({
      isLoading: false,
      loaded: false,
    })
  }

  private customTierCell = (props: any) => {
    return <td>
      <h4 className="prt-wrapper_sub-heading mty">Tier {props.dataItem.tier}</h4>
    </td>
  }
  private getPopularTopics = (communityPersonIds: number[]): Promise<string> => {
    if (communityPersonIds.length) {
      const searchPayload = bodybuilder()
        .size(0)
        .query(FilterType.terms, 'community_person_id', communityPersonIds)
        .aggregation(AggregationType.terms, 'text.keyword', {size: 5}, 'popular_topics')
        .build()

      return this.queryIndex(searchPayload, IndexName.TAGS)
        .then((popularTopicResult) => {
          const popularTopics = selectPopularTopics(popularTopicResult)

          return popularTopics.join(', ')
        })
    }

    return Promise.resolve('')
  }
}

export interface ProgressAnalyzerProps {
  progressValue: number,
  progressThreshold?: ProgressThreshold
}

export class ProgressAnalyzer extends Component<ProgressAnalyzerProps, any> {
  constructor(props: ProgressAnalyzerProps) {
    super(props)
  }

  public render() {
    let className = 'red'
    switch (true) {
      case this.props.progressThreshold && this.props.progressValue >= this.props.progressThreshold.level_two:
        className = 'green'
        break
      case this.props.progressThreshold && this.props.progressValue >= this.props.progressThreshold.level_one:
        className = 'yellow'
        break
    }

    return <td>
      <div className="prt-wrapper">
        <strong className="prt-wrapper_title">
          {this.props.progressValue}%
        </strong>
        <div className="prt-wrapper_bar-wrap">
          <span style={{width: `${this.props.progressValue}%`}} className={`prt-wrapper_bar prt-wrapper_bar--${className}`}></span>
        </div>
      </div>
    </td>
  }
}

export const AiKnowledgeTierComponent: ConnectedComponentClass<ComponentType<AiKnowledgeTierTableComponent>, Fields> = GenericRedux.registerNewComponent(
  AiKnowledgeTierTableComponent, 'analytics_aiknowledgetier', {})
