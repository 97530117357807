import { AppCuesModal } from 'components/app-cues/component'
import * as Actions from 'components/banner/actions'
import {
  BannerType,
  CAPPED,
  CLOSED,
  COMMUNITY_SYSTEM_LINK_TEXT,
  MESSAGES,
  UPDATE_LINK_TEXT,
} from 'components/banner/constants'
import { CountdownComponent } from 'components/banner/countdown'
import { StaticNotification } from 'components/banner/staticNotification'
import { UsageLimit } from 'components/banner/usageLimit'
import { ACCOUNT_DUNNING } from 'components/common/constants'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { DOMAINS_URL, INTEGRATIONS_URL, UPDATE_CARD_URL } from 'components/settings-editor/constants'
import { RasaContext } from 'context'
import { differenceInSeconds, format, parseISO } from 'date-fns'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import { isBeforeCutoff, isInCutoffWindow, isInGenerationWindow } from 'shared/data-layer/issues'
import { ScheduleFrequency } from 'shared_server_client/constants'
import { isOverContactLimit } from 'shared_server_client/types/billing_plan'
import { isApproachingLimit, wontSend } from 'shared_server_client/usageBase'
import './styles.scss'
import { BannerState } from './types'
import { SUPPORT_EMAIL, SUPPORT_MAIL_TO } from '../../constants';

export interface RasaSystemStatus {
  isActive: boolean
  isPublish: boolean
  message: string
  type: string
  timestamp: Date
}

declare const rasaSystemStatus: RasaSystemStatus

export const noStatus: RasaSystemStatus = {
  message: '',
  isActive: false,
  isPublish: false,
  type: 'information',
  timestamp: new Date(),
}

export const getSystemStatus = (): RasaSystemStatus => {
  try {
    if (rasaSystemStatus.timestamp) {
      rasaSystemStatus.timestamp = new Date(rasaSystemStatus.timestamp)
    }
    rasaSystemStatus.isPublish = true
    return rasaSystemStatus
  } catch (error) {
    return noStatus
  }
}

interface BannerProps {
  app: any,
  notifications: any,
  banner: any,
  push: any,
}

class BannerComponent extends Component<BannerProps, BannerState> {
  public static contextType = RasaContext
  private _interval: any
  private _isMobile: boolean
  private _isAuthenticated: boolean

  private communityId: string = null

  constructor(props: BannerProps) {
    super(props);
    this.state = {
      accountCancelled: false,
      accountDunning: false,
      accountExpired: false,
      accountSuspended: false,
      accountSuspendReason: '',
      activeSchedule: null,
      activeScheduleFrequency: '',
      communitySystemDisconnected: false,
      failedCommunityIntegrations: false,
      currentPlan: null,
      currentTime: null,
      cutoffEnd: null,
      isInvalidDomain: false,
      lastSendStatus: null,
      generationWindowInSec: null,
      pathName: null,
      productSubscription: null,
      nextBillingDate: null,
      nextIssue: null,
      notifications: {},
      sendTime: null,
      trialExpirationTime: null,
      usageStats: null,
    }
    this.close = this.close.bind(this)
    this.sendUpgradeReminder = this.sendUpgradeReminder.bind(this)
  }

  public componentDidMount() {
    const store = this.context.store.getState()
    this._isMobile = store.config.isMobile
    this._isAuthenticated = store.auth.authenticated

    this.setState({
      notifications: this.props.banner || {},
      pathName: window.location.pathname,
    })

    if (this._isAuthenticated) {
      this.context.user.init()
        .then(({ person, activeCommunity }) => {
          this.communityId = activeCommunity.communityId
          const newState: any = {
            accountCancelled: (activeCommunity.billingInfo.productSubscription || {}).is_cancelled > 0,
            accountDunning: this.isDunningStatus((activeCommunity.billingInfo.productSubscription || {}).status || ''),
            accountExpired: person.accountExpired(),
            accountSuspended: (activeCommunity.data || {}).community_account_suspended > 0,
            accountSuspendReason: (activeCommunity.data || {}).community_account_suspend_reason || '',
            activeSchedule: !!activeCommunity._communityInfo.data.schedule[0].is_active,
            activeScheduleFrequency: activeCommunity._communityInfo.data.schedule[0].frequency,
            communitySystemDisconnected: activeCommunity.disconnectedCommunitySystems.length > 0,
            failedCommunityIntegrations: activeCommunity.failedCommunityIntegrations.length > 0,
            currentPlan: activeCommunity.billingInfo.currentPlan,
            isInvalidDomain: person.isInvalidDomain,
            lastSendStatus: activeCommunity._communityInfo.data.schedule[0].last_send_status,
            productSubscription: activeCommunity.billingInfo.productSubscription,
            nextBillingDate: activeCommunity.billingInfo.nextBillingDate,
            nextIssue: activeCommunity.nextIssue,
            trialExpirationTime: person.accountInfo.expiration_date,
            usageStats: activeCommunity.billingInfo.usageStats,
          }
          if (activeCommunity._communityInfo && activeCommunity._communityInfo.data.schedule &&
            activeCommunity._communityInfo.data.schedule.length && activeCommunity.nextIssue) {
            const now = new Date()
            newState.cutoffEnd = new Date(activeCommunity.nextIssue.production_cutoff)
            newState.sendTime = new Date(activeCommunity.nextIssue.date)
            newState.generationWindowInSec = differenceInSeconds(
              newState.sendTime,
              now > newState.cutoffEnd ? now : newState.cutoffEnd,
            )
          }
          this.setState(newState, () => this.setIntervals())
      })
    }

  }

  public componentWillUnmount() {
    clearInterval(this._interval)
  }

  public render() {
    const {nextBillingDate, nextIssue, usageStats} = this.state
    return (
      <div className="banner-container">
        <div>
        {
          this.showElement(BannerType.ACCOUNT_DUNNING) &&
          <StaticNotification isMobile={this._isMobile}
            class="warning"
            close={this.close}
            linkText={UPDATE_LINK_TEXT}
            linkUrl={UPDATE_CARD_URL}
            push={this.props.push}
            name={BannerType.ACCOUNT_DUNNING}
          />
        }
        {
          this.showElement(BannerType.ACCOUNT_SUSPENDED) &&
          <StaticNotification isMobile={this._isMobile}
            class="warning"
            linkText={this.getSuspendedUpdateLink()}
            linkUrl={UPDATE_CARD_URL}
            message={this.getSuspendedMessage()}
            push={this.props.push}
            name={BannerType.ACCOUNT_SUSPENDED}
          />
        }
        {
          this.showElement(BannerType.ACCOUNT_CANCELLED) &&
          <StaticNotification isMobile={this._isMobile}
            timeOut={this.state.generationWindowInSec * 1000}
            message={MESSAGES[BannerType.ACCOUNT_CANCELLED].replace('{endDate}',
            this.state.productSubscription.end_date ? format(parseISO(this.state.productSubscription.end_date), 'iii, MMM do') : '')}
            class="warning"
            close={this.close}
            name={BannerType.ACCOUNT_CANCELLED}
          />
        }
        {
          this.showElement(BannerType.COUNTDOWN) &&
          <CountdownComponent isMobile={this._isMobile}
            timeTillDate={nextIssue.production_cutoff}
            name={BannerType.COUNTDOWN}
            close={this.close}
          />
        }
        {
          this.showElement(BannerType.IN_GENERATION) &&
          <StaticNotification isMobile={this._isMobile}
            timeOut={this.state.generationWindowInSec * 1000}
            class="warning"
            close={this.close}
            name={BannerType.IN_GENERATION}
          />
        }
        {
          this.showElement(BannerType.OVER_CONTACT_LIMIT) &&
          <StaticNotification isMobile={this._isMobile}
            timeOut={this.state.generationWindowInSec * 1000}
            class="warning"
            close={this.close}
            name={BannerType.OVER_CONTACT_LIMIT}
          />
        }
        {
          this.showElement(BannerType.EXPIRED_ACCOUNT) &&
          <StaticNotification isMobile={this._isMobile}
            timeOut={this.state.generationWindowInSec * 1000}
            class="warning"
            close={this.close}
            name={BannerType.EXPIRED_ACCOUNT}
          />
        }
        {
          this.showElement(BannerType.MANUAL_SCHEDULE_ENABLED) &&
          <StaticNotification isMobile={this._isMobile}
            timeOut={this.state.generationWindowInSec * 1000}
            class="information"
            close={this.close}
            name={BannerType.MANUAL_SCHEDULE_ENABLED}
          />
        }
        {
          this.showElement(BannerType.IS_APPROACHING_LIMIT) &&
          <UsageLimit isMobile={this._isMobile}
            usageStats={usageStats}
            class="information"
            close={this.close}
            name={BannerType.IS_APPROACHING_LIMIT}
            nextBillingDate={nextBillingDate}
            sendReminder={this.sendUpgradeReminder}
          />
        }
        {
          this.showElement(BannerType.WONT_SEND) &&
          <UsageLimit isMobile={this._isMobile}
            usageStats={usageStats}
            class="warning"
            close={this.close}
            name={BannerType.WONT_SEND}
            nextBillingDate={nextBillingDate}
            sendReminder={this.sendUpgradeReminder}
          />
        }
        {
          this.showElement(BannerType.SYSTEM_STATUS) &&
          <StaticNotification isMobile={this._isMobile}
            message={getSystemStatus().message}
            class={getSystemStatus().type}
            name={BannerType.SYSTEM_STATUS}
          />
        }
        {
          this.showElement(BannerType.COMMUNITY_SYSTEM_DISCONNECTED) &&
          <StaticNotification isMobile={this._isMobile}
            timeOut={this.state.generationWindowInSec * 1000}
            buttonText="Dismiss"
            buttonUrl={INTEGRATIONS_URL}
            class="warning"
            close={this.close}
            linkText={COMMUNITY_SYSTEM_LINK_TEXT}
            linkUrl={INTEGRATIONS_URL}
            push={this.props.push}
            name={BannerType.COMMUNITY_SYSTEM_DISCONNECTED}
          />
        }
        {
          this.showElement(BannerType.FAILED_COMMUNITY_INTEGRATION) &&
            <StaticNotification isMobile={this._isMobile}
                                timeOut={this.state.generationWindowInSec * 1000}
                                buttonText="Dismiss"
                                buttonUrl={SUPPORT_MAIL_TO}
                                class="warning"
                                close={this.close}
                                linkText={SUPPORT_EMAIL}
                                linkUrl={SUPPORT_MAIL_TO}
                                push={this.props.push}
                                name={BannerType.FAILED_COMMUNITY_INTEGRATION}
              />
        }
        {
          this.showElement(BannerType.INVALID_DOMAIN) &&
          <StaticNotification isMobile={this._isMobile}
            timeOut={this.state.generationWindowInSec * 1000}
            buttonText="Dismiss"
            buttonUrl={DOMAINS_URL}
            class="warning"
            close={this.close}
            linkText={UPDATE_LINK_TEXT}
            linkUrl={DOMAINS_URL}
            push={this.props.push}
            name={BannerType.INVALID_DOMAIN}
          />
        }
        </div>
        <AppCuesModal isMobile={this._isMobile} />
      </div>
    )
  }

  private showElement = (bannerType: BannerType): boolean => {
    const { activeSchedule, currentPlan, currentTime, lastSendStatus, nextIssue, usageStats } = this.state
    const notifications = this.props.banner || {}
    if ( notifications[bannerType] === CLOSED ) {
      return false
    }
    switch (bannerType) {
      case BannerType.ACCOUNT_DUNNING:
        return this._isAuthenticated && (!this.props.app || this.props.app.route !== DashboardMenuOption.billing) &&
          this.state.accountDunning && !this.state.accountSuspended
      case BannerType.ACCOUNT_SUSPENDED:
        return this._isAuthenticated && (!this.props.app || this.props.app.route !== DashboardMenuOption.billing) &&
          this.state.accountSuspended === true
      case BannerType.ACCOUNT_CANCELLED:
        return this._isAuthenticated && (!this.props.app || this.props.app.route !== DashboardMenuOption.billing) &&
          this.state.accountCancelled === true
      case BannerType.EXPIRED_ACCOUNT:
        return !this.state.accountCancelled && !this.state.accountSuspended && this.state.accountExpired
      case BannerType.OVER_CONTACT_LIMIT:
        return this._isAuthenticated && !this.state.accountExpired && !this.state.accountCancelled &&
          this.state.usageStats && isOverContactLimit(this.state.usageStats)
      case BannerType.COUNTDOWN:
        return this._isAuthenticated && activeSchedule && nextIssue && nextIssue.schedule.start_date
          && this.state.activeScheduleFrequency !== ScheduleFrequency.manually
          && isInCutoffWindow(nextIssue, currentTime)
          && !wontSend(currentPlan, usageStats, lastSendStatus, nextIssue.production_cutoff)
      case BannerType.IN_GENERATION:
        return this._isAuthenticated && activeSchedule && nextIssue && nextIssue.schedule.start_date
          && isInGenerationWindow(nextIssue, currentTime)
          && !wontSend(currentPlan, usageStats, lastSendStatus, nextIssue.production_cutoff)
      case BannerType.IS_APPROACHING_LIMIT:
        return this._isAuthenticated && activeSchedule && nextIssue
        && isApproachingLimit(currentPlan, usageStats, this.state.activeScheduleFrequency)
        && lastSendStatus !== CAPPED
        && !wontSend(currentPlan, usageStats, lastSendStatus, nextIssue.production_cutoff)
      case BannerType.SYSTEM_STATUS:
        return !!getSystemStatus().isActive && differenceInSeconds(getSystemStatus().timestamp, new Date()) <= 0
      case BannerType.WONT_SEND:
        return this._isAuthenticated && activeSchedule && nextIssue
        && wontSend(currentPlan, usageStats, lastSendStatus, nextIssue.production_cutoff)
      case BannerType.COMMUNITY_SYSTEM_DISCONNECTED:
        return this._isAuthenticated && this.state.communitySystemDisconnected
      case BannerType.FAILED_COMMUNITY_INTEGRATION:
        return this._isAuthenticated && this.state.failedCommunityIntegrations
      case BannerType.INVALID_DOMAIN:
          return this._isAuthenticated && this.state.isInvalidDomain
      case BannerType.MANUAL_SCHEDULE_ENABLED:
        return this._isAuthenticated && this.state.pathName.startsWith(DashboardMenuOption.schedule)
          && this.state.activeScheduleFrequency === ScheduleFrequency.manually
      default:
        return false
    }
  }

  private close = (name: string) => {
    this.context.store.dispatch(Actions.closeBanner(name))
    this.setState({
      notifications: {
        ...this.state.notifications,
        [name]: CLOSED,
      },
    })
  }

  private setAnInterval = (end) => {
    return new Promise((resolve, reject) => {
    this._interval = setInterval(() => {
      const now = new Date()
      const timeToEnd = differenceInSeconds(new Date(end), now)
      if (timeToEnd < 0) {
        clearInterval(this._interval)
        this.setState({ currentTime: now })
        resolve(true)
      }
    }, 1000)
    })
  }

  private setIntervals = () => {
    const {nextIssue} = this.state
    if (nextIssue && isBeforeCutoff(nextIssue)) {
      this.setAnInterval(nextIssue.cutoff).then(() => this.setAnInterval(nextIssue.production_cutoff))
    }
    if (nextIssue && isInCutoffWindow(nextIssue)) {
      this.setAnInterval(nextIssue.production_cutoff)
    }
  }

  private sendUpgradeReminder = (name: string) => {
    const url: string = AjaxWrapper.getServerUrl() + `/send-notification/${this.communityId}`
    AjaxWrapper.ajax(url, HttpMethod.POST, {type: name, nextBillingDate: this.state.nextBillingDate})
  }

  private isDunningStatus(status: string) {
    // matching this to api code
    return ['unpaid', 'cancelled_from_dunning', 'dunning'].includes(status.toLowerCase())
  }

  private getSuspendedMessage() {
    if (this.state.accountSuspended) {
      if (this.state.accountSuspendReason === ACCOUNT_DUNNING) {
        return 'Your credit card is expired or invalid. Please update your credit card information.'
      } else {
        return `Your account has been suspended. Please contact ${SUPPORT_EMAIL}.`
      }
    }
    return ''
  }

  private getSuspendedUpdateLink() {
    if (this.state.accountSuspended && this.state.accountSuspendReason === ACCOUNT_DUNNING) {
      return UPDATE_LINK_TEXT
    }
    return ''
  }

}
export const Banner = connect(
  ({app, notifications, flash, banner}: any) => ({app, notifications, flash, banner}),
  {
    push: Router.push,
  },
)(BannerComponent)
