import { orderBy, SortDescriptor } from '@progress/kendo-data-query'
import * as KendoChart from '@progress/kendo-react-charts'
import { Grid, GridColumn, GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid'
import { DropdownComponent, DropdownOption } from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { format } from 'date-fns'
import { Dataset, DatasetParam } from 'generic/dataset'
import React from 'react'
import { Col, Row } from 'reactstrap'
import * as GenericRedux from 'generic/genericRedux'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './_analytics.scss'
import * as Constants from "./constants";

interface ChartData {
  name: string,
  counts: number[],
  uniques: number[]
}

interface ChartProps {
  name: string,
  dataKey: string,
  labels: string[],
  data: ChartData[],
}

const chartTooltip = (context: KendoChart.TooltipContext) => <div>{context.point.value}</div>

const ChartComponent = (props: ChartProps) =>
  <div className="chart">
      <KendoChart.Chart zoomable={false} >
      <KendoChart.ChartTitle text={props.name}/>
      <KendoChart.ChartTooltip shared={false} render={chartTooltip}/>
      <KendoChart.ChartCategoryAxis>
        <KendoChart.ChartCategoryAxisItem categories={props.labels} startAngle={45} />
      </KendoChart.ChartCategoryAxis>
      <KendoChart.ChartSeries>
      { props.data.map((data: ChartData, idx: number) =>
         <KendoChart.ChartSeriesItem key={idx} type="column" data={data[props.dataKey]} name={data.name}/>
      )}
      </KendoChart.ChartSeries>
    </KendoChart.Chart>
  </div>


interface Pagination {
  skip: number,
  take: number,
}

interface RecommendationsPerformanceState {
  community: any,
  dataLoading: boolean,
  data: any,
  exception?: any,
  issueLoading: boolean,
  issues: DropdownOption[],
  pagination: Pagination,
  selectedIssue: DropdownOption,
  sort: SortDescriptor[]
}

const initialState: RecommendationsPerformanceState = {
  community: null,
  dataLoading: false,
  data: null,
  issueLoading: true,
  issues: [],
  pagination: {
    skip: 0,
    take: 10,
  },
  selectedIssue: {
    key: 'No Issue',
    value: -1,
  },
  sort: [],
}

export class RecommendationsPerformanceClass extends RasaAnalyticsComponent<RecommendationsPerformanceState, any> {

  public static contextType = RasaContext

  constructor(props) {
    super(props, initialState)
  }

  public componentDidMount() {
    this.context.user.init().then(({activeCommunity}) => {
      this.setState({
        community: activeCommunity,
        issuesLoading: true,
        issues: []
      }, () => this.loadIssues().then((issues: any[]) => {
        this.setState({
          issueLoading: false,
          issues: this.issuesAsDropdownOptions(issues),
        }, () => {
          if (this.state.issues.length) {
            this.issueSelected({ selected: this.state.issues[0] })
          }
        })
      }))
    })
  }

  public render() {
    return <div className="analytics recommendations">
        <HeaderComponent
          title={'REPORTS'}
          subTitle={'Recommendation Performance'}
          description={['This report provides insights into the performance of the recommendations made by the AI engine.']}
        />
      { this.issueSelector() }
      { this.dataContainer() }
      </div>
  }

  private sort = (e: GridSortChangeEvent) => {
    this.setState({
      sort: e.sort,
    })
  }

  private page = (e: GridPageChangeEvent) => {
    this.setState({
      pagination: {
        skip: e.page.skip,
        take: e.page.take,
      }
    })
  }

  private personsToDisplay = (): any[] => {
    const orderedData: any[] = orderBy(this.state.data.persons, this.state.sort)
    return orderedData.slice(this.state.pagination.skip, this.state.pagination.skip + this.state.pagination.take)
  }

  private dataContainer = () => {
    return this.state.dataLoading ? <Loading size="64" /> : !this.state.data ? <div></div> :
      <div>
        <Row>
          <h3>All Model Stats</h3>
        </Row>
        <Row>
          <Col>
            <ChartComponent name="Details By Model" data={this.chartDataByModel('details')} labels={this.chartDataByModelLabels('details')} dataKey="counts"/>
          </Col>
          <Col>
            <ChartComponent name="Newsletters By Model" data={this.chartDataByModel('details')} labels={this.chartDataByModelLabels('details')} dataKey="uniques"/>
          </Col>
        </Row>
        <Row>
          <h3>Top Engaged People</h3>
          </Row>
        <Row>
          <Col>
            <Grid data={this.personsToDisplay()}
                  sortable={{
                    allowUnsort: true,
                  }}
                  sort={this.state.sort}
                  onSortChange={(e: GridSortChangeEvent) => this.sort(e)}
                  skip={this.state.pagination.skip}
                  take={this.state.pagination.take}
                  total={this.state.data.persons.length}
                  pageSize={this.state.pagination.take}
                  onPageChange={(e: GridPageChangeEvent) => this.page(e)}
                  pageable={true}>
              <GridColumn field="community_person_id" title="ID"/>
              <GridColumn field="unique_tags" title="# Tags"/>
              <GridColumn field="unique_clicks" title="# Clicks"/>
              <GridColumn field="last_click" title="Last Click"/>
              <GridColumn field="unique_opens" title="# Opens"/>
              <GridColumn field="last_open" title="Last Open"/>
              {this.chartDataModels('details').map((model: string) =>
                <GridColumn field={`details.model.${model}.count`} title={model}/>)}
            </Grid>
          </Col>
        </Row>
        <Row>
          <h3>Model Stats - Top People</h3>
        </Row>
        <Row>
          <Col>
            <ChartComponent name="Details By Model" data={this.chartDataByModel('topDetails')} labels={this.chartDataByModelLabels('topDetails')} dataKey="counts"/>
          </Col>
          <Col>
            <ChartComponent name="Newsletters By Model" data={this.chartDataByModel('topDetails')} labels={this.chartDataByModelLabels('topDetails')} dataKey="uniques"/>
          </Col>
        </Row>
      </div>
  }

  private chartDataByModelLabels = (key: string): string[] => {
    return Object.keys(this.state.data[key])
  }

  private chartDataModels = (key: string) => {
    const allModels = Object.keys(this.state.data[key]).reduce((acc, record_type) => {
      const recordData = this.state.data[key][record_type]
      Object.keys(recordData.model).forEach((model) => acc.add(model))
      return acc
    }, new Set())
    return Array.from(allModels)
  }

  private chartDataByModel = (key: string): ChartData[] => {
    // need to invert the data - this is organized by record type, then model.
    // but for kendo, if we want record_type to be the x-axis, I need to create
    // data sets that organize the model data points.
    // step 1: what are the distinct models?
    return this.chartDataModels(key).map((model: string) => {
      return {
        name: model,
        counts: this.chartDataByModelLabels(key).map((record_type: string) => {
          const recordData = this.state.data[key][record_type]
          return recordData.model[model] ? recordData.model[model].count : 0
        }),
        uniques: this.chartDataByModelLabels(key).map((record_type: string) => {
          const recordData = this.state.data[key][record_type]
          return recordData.model[model] ? recordData.model[model].unique : 0
        })
      }
    })
  }

  private issueSelector = () => {
    return this.state.issueLoading ? <Loading size="64" /> :
      this.state.issues.length === 0 ? <p className="no-data-tag">{Constants.NO_DATA_COPY}</p> :
        <div>
          <Row>
            <Col md="4">
              <Row className="issue-date-dropdown">
                <Col>
                  <div className="dropdown">
                    <DropdownComponent data={this.state.issues}
                      selected={this.state.selectedIssue.key}
                      onChange={this.issueSelected}/>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
  }

  private issuesAsDropdownOptions = (issues: any[]): DropdownOption[] => {
    return issues.map((issue) => {
        const d: Date = new Date(issue.send_at_in_timezone)
        const dateWithoutOffset = d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
        const key = format(new Date(dateWithoutOffset), 'iiii, MMM d H:mm')
        return {
          context: issue,
          key,
          value: issue.id,
        }
      })
  }

  private issueSelected = (e: any) => {
    this.setState({
      selectedIssue: e.selected,
      dataLoading: true,
      data: null
    }, () => {
      this.loadData().then((data) => {
        this.setState({
          dataLoading: false,
          data,
        })
      }).catch((ex) => {
        this.setState({
          dataLoading: false,
          data: null,
          exception: ex
        })
      })
    })
  }

  private loadData = () => {
    const params: DatasetParam[] = [
      { param: 'issue', value: this.state.selectedIssue.value },
    ]
    return new Dataset().loadCommunityDataset('recommendationPerformance', this.state.community.communityId, params)
      .catch((ex) => {
        this.setState({
          exception: ex
        })
        return {}
      })
  }

  private loadIssues = () => {
    const params: DatasetParam[] = [
      {param: 'pageSize', value: 10},
    ]
    return new Dataset().loadCommunityDataset('communityIssues', this.state.community.communityId, params)
      .then((response) => response[0])
      .catch((ex) => {
        this.setState({
          exception: ex
        })
        return []
    })
}

}

export const RecommendationsPerformanceComponent = GenericRedux.registerNewComponent(
  RecommendationsPerformanceClass,
  'analytics_recommendations',
  {}
)
