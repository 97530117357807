import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import bodybuilder from 'bodybuilder'
import { DropdownComponent} from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { DateRangesAsDropdownOptions, getMessageSendDateFilterField, SuspectFilterDropdownOptions } from 'elasticsearch/constants'
import { AggregationType, FilterType, IndexName, toFilter } from 'elasticsearch/constants'
import { ResponseAggregate, ResponsePayload } from 'elasticsearch/types'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import { Button } from 'reactstrap'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import { ConnectedComponentClass } from 'react-redux'
import {ComponentType} from "react";
import {Fields} from "../../shared/modals";
import * as Utils from './utils'

interface Source {
  name: string,
  totalClicks?: number,
  uniqueClicks?: number,
  suspectClick?: number
}

type Sources = Source[]

interface SourcesProps extends ElasticsearchProps<Sources> {
  dateRange: any,
  suspectClick?: any
  timezone?: string
  useTimezoneForAnalytics?: any
}

interface SourcesState {
  loaded: boolean,
}

const SOURCE_NAME_AGG: string = 'source_name'

export class SourcesComponent extends RasaAnalyticsComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      selectedDateRange: DateRangesAsDropdownOptions[2],
      selectedSuspectClick: SuspectFilterDropdownOptions[0]
    }
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
  }

  public render() {
    return(
      <div className="analytics-component">
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Sources'}
          description={['The sources your contacts are clicking on the most.']}
        />
        <div>
        </div>
        <div className="dropdown">
          <DropdownComponent data={DateRangesAsDropdownOptions}
                             selected={this.state.selectedDateRange.key}
                             onChange={this.dateChanged}/>
        </div>
        <div className="date-range-picker">
          {this.state.selectedDateRange.key === '7' ?
          <DateRangePicker onChange={this.createCustomDate}
                           min={this.state.minCustomDateRange}
                           max ={new Date()} /> : null}
        </div>
        <div className="dropdown">
          <DropdownComponent data={SuspectFilterDropdownOptions}
                             selected={this.state.selectedSuspectClick.key}
                             onChange={this.suspectedClickChanged}/>
        </div>
        {this.state.isFilterLoaded &&
          <AnalyticsSources dateRange={this.state.selectedDateRange.value}
                          timezone={this.state.timezone}
                          useTimezoneForAnalytics={this.state.useTimezoneForAnalytics}
                          suspectClick={this.state.selectedSuspectClick.value}/>}
      </div>
    )
  }
}

export class AnalyticsSourcesComponent extends ElasticsearchComponent<Sources, SourcesProps, SourcesState> {
  private xlsxExport: any = null
  constructor(props: SourcesProps) {
    super(props, IndexName.EVENTS)
    this.state = {
      loaded: false,
    }
    this.reportName = Constants.REPORT_NAMES.SOURCES
  }

  public componentDidUpdate = (oldProps: SourcesProps) => {
    if ( this.props.dateRange !== oldProps.dateRange || this.props.suspectClick !== oldProps.suspectClick ) {
      this.search()
    }
  }

  public parseResponse = (payload: ResponsePayload): Promise<Sources> => {
    this.setState({
      loaded: true,
    })
    const aggregations = payload.aggregations[SOURCE_NAME_AGG]
    return Promise.resolve(aggregations.buckets.map((aggregation: ResponseAggregate) => ({
      name: aggregation.key,
      totalClicks: aggregation.doc_count,
      uniqueClicks: aggregation.unique.value,
    })))
  }

  public searchPayload = (): any => {
    const search = bodybuilder().size(0).filter(
      FilterType.range, getMessageSendDateFilterField(this.props.dateRange, this.props.useTimezoneForAnalytics), toFilter(this.props.dateRange, this.props.timezone, this.props.useTimezoneForAnalytics))
    if (this.props.suspectClick && Utils.isRealClickSelected(this.props.suspectClick)) {
      search.addFilter(FilterType.term, 'suspect_click', 0)
    }
    return this.addAggregation(search, {
      type: AggregationType.terms,
      field: 'source_name.keyword',
      name: SOURCE_NAME_AGG,
      extra: { size: '25' },
      unique_on: 'community_person_id',
    }).build()
  }

  public render = () => <div>
    {this.state.loaded &&
    <div className="sources-chart">
    {this.props.results && this.props.results.length > 0 ?
      <div>
      <Button
        disabled={this.props.results.length < 1}
        onClick={() => this.xlsxExport.save()}>
        Export xlsx
      </Button>
      <ExcelExport data={this.props.results}
        fileName="RasaAdminReports.xlsx"
        ref={(exporter) => {this.xlsxExport = exporter}}>
        <Grid data={this.props.results} className="analytics-counts-grid" scrollable="none">
          <GridColumn field="name" title="Source"/>
          <GridColumn field="totalClicks" title="Total Clicks"/>
          <GridColumn field="uniqueClicks" title="Unique Clicks"/>
        </Grid>
      </ExcelExport>
      </div> :
        <div>
          <p className="no-data-tag">
            {Constants.NO_DATA_COPY}
          </p>
      </div>}
    </div>}
  </div>
}

export const AnalyticsSources: ConnectedComponentClass<ComponentType<AnalyticsSourcesComponent>, Fields> = GenericRedux.registerNewComponent(
  AnalyticsSourcesComponent, 'home_sources', {})
